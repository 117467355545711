import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../../components/bulma/BulmaLayout';
import SectionWrapper from '../../components/bulma/BulmaSectionWrapper';
import Content from '../../components/Content';
import PageTitle from '../../components/PageTitle';
import ContactCallout from '../../components/ContactCallout';
import LodgingPolicy from '../../components/lodging/LodgingPolicy';
import Amenities from '../../components/lodging/Amenities';
import Cancelation from '../../components/lodging/Cancelation';
import PrivateWater from '../../components/lodging/PrivateWater';
import LodgingSummaryIcons from '../../components/lodging/LodgingSummaryIcons';
import Rates from '../../components/lodging/Rates';
import LodgingImageLightbox from '../../components/lodging/LodgingImageLightbox';

const RiversideUnitsPage = ({ data }) => {
  data.lodging.policies.minstay = data.room.minstay;
  data.lodging.policies.maxstay = data.room.maxstay;

  const room = data.room;
  const content = data.room.content.childMarkdownRemark;
  const site = data.site;
  const lodging = data.lodging;
  const policies = data.lodging.policies;

  return (
    <Layout>
      <Helmet>
        <title>
          {site.siteTitle} - {content.frontmatter.title}
        </title>
        <meta name="description" content={content.frontmatter.metaDescription} />
      </Helmet>
      <div className="lodging-header-bg">
        <div className="container lodging-header-container">
        </div>
      </div>
      <SectionWrapper>
        <PageTitle text={content.frontmatter.title} />
        <div className="columns">
          <div className="column is-two-thirds">
            <LodgingImageLightbox images={room.images} />
            <div className="showcase">
              <LodgingSummaryIcons guests={room.guests} bedrooms={room.bedrooms} bath={room.bath} beds={room.beds} />
              <Content html={content.html} />
            </div>
            <Amenities data={room.amenities} />
            <LodgingPolicy policies={policies} />
            <PrivateWater text={lodging.privateWater} />
          </div>
          <div className="column">
            <ContactCallout text={site.contactCalloutText} phone={site.contact.phone} />
            <Rates rate={room.rate} deposit={room.deposit} />
            <Cancelation data={room.cancelation} />
          </div>
        </div>
      </SectionWrapper>
    </Layout>
  );
};

export default RiversideUnitsPage;

export const query = graphql`
  query HousePageQuery {
    site: siteJson {
      contactCalloutText
      contact {
        company
        address1
        city
        state
        zipcode
        phone
        email
      }
      siteTitle
      guideTrips {
        desc
        rate
      }
    }
    lodging: lodgingJson {
      specialTitle
      specialDescription
      specialDiscountPercent
      privateWater
      policies {
        checkout
        checkin
        pets
        smoking
      }
    }
    room: houseJson {
      title
      content {
        childMarkdownRemark {
          html
          frontmatter {
            title
            metaDescription
          }
        }
      }
      guests
      bedrooms
      bath
      beds
      guests
      bedrooms
      bath
      beds
      bedsText
      amenities
      rate
      deposit
      cancelation
      minstay
      maxstay
      images {
        src
        caption
      }
    }
  }
`;
